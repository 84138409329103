function facebook() {
}

facebook.prototype.login = function () {
  FB.login((response) => {
    console.log(response)

    $('form').request('onLogin', {
      data: {loginProvider: 'FACEBOOK'}
    })

  }, {scope: 'public_profile,email'})
}

facebook.prototype.checkLoginState = function () {               // Called when a person is finished with the Login Button.
  FB.getLoginStatus((response) => {   // See the onlogin handler
    this.statusChangeCallback(response)
  })
}

facebook.prototype.statusChangeCallback = function (response) {  // Called with the results from FB.getLoginStatus().
  console.log('statusChangeCallback')
  console.log(response)                   // The current login status of the person.
  if (response.status === 'connected') {   // Logged into your webpage and Facebook.
    // this.testAPI()
    this.updateBtn( 'continue' )
    console.log('continue')

  } else {                                 // Not logged into your webpage or we are unable to tell.
    this.updateBtn( 'login' )
    console.log('login')
    // document.getElementById('status').innerHTML = 'Please log ' + 'into this webpage.'
  }
}

facebook.prototype.testAPI = function () {                      // Testing Graph API after login.  See statusChangeCallback() for when this call is made.
  console.log('Welcome!  Fetching your information.... ')
  FB.api('/me?fields=name,email', function (response) {
    console.log(response)
    console.log('Successful login for: ' + response.name)
  })

}

facebook.prototype.updateBtn = function( state ) {
  if ( state == 'continue' ) this.showContinueBtn()
  if ( state == 'login' ) this.showLoginBtn()
}

facebook.prototype.showContinueBtn = function() {
  $('#fb_bt_continue').addClass('show')
  $('#fb_bt_login').removeClass('show')
}

facebook.prototype.showLoginBtn = function() {
  $('#fb_bt_login').addClass('show')
  $('#fb_bt_continue').removeClass('show')
}

window.fbAsyncInit = function () {
  // Get the AppId
  scriptElmt = document.getElementById('FacebookLogin')

  FB.init({
    appId: scriptElmt.dataset.appid,
    cookie: true,
    xfbml: true,
    version: 'v11.0'
  })

  FB.getLoginStatus(function (response) {   // Called after the JS SDK has been initialized.
    let fbManager = new facebook()
    window.fbManager = fbManager
    fbManager.statusChangeCallback(response)        // Returns the login status.
  })

}
